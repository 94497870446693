import { Injectable, HostListener, OnDestroy, PLATFORM_ID, Inject, signal } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { CookieComponent } from '../components/cookie/cookie.component';
import { GoogleService } from './google.service';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FacebookService } from './facebook.service';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
// import moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class CookiesService implements OnDestroy {
    private unsubscribe$ = new Subject();
    private matomosubject: BehaviorSubject<boolean>;
    private salesmanagosubject: BehaviorSubject<boolean>;
    private awinsubject: BehaviorSubject<boolean>;
    isBrowser: boolean = false;
    media = signal<string>('lg');
    smallMode = signal<boolean>(false);
    constructor(
        protected dialog: MatDialog,
        protected googleServie: GoogleService,
        @Inject(PLATFORM_ID) protected platformId: any,
        @Inject(DOCUMENT) protected document: any,
        protected mediaObserver: BreakpointObserver,
        protected route: ActivatedRoute,
        protected facebookService: FacebookService
    ) {
        this.matomosubject = new BehaviorSubject(false);
        this.salesmanagosubject = new BehaviorSubject(false);
        this.awinsubject = new BehaviorSubject(false);
        this.isBrowser = isPlatformBrowser(this.platformId);

        if(this.isBrowser) {
            setTimeout(() => {
                this.checkAwinParams();
            }, 50);
        }
        this.initMedia();
    }
    
    initMedia(): void {
        if (this.mediaObserver.isMatched(Breakpoints.XSmall)) {
            this.media.set('xs');
        }
        if (this.mediaObserver.isMatched(Breakpoints.Small)) {
            this.media.set('sm');
        }
        if (this.mediaObserver.isMatched(Breakpoints.Large)) {
            this.media.set('lg');
        }
        if (this.mediaObserver.isMatched(Breakpoints.XLarge)) {
            this.media.set('xl');
        }

        this.smallMode.set(this.media() === 'xs' || this.media() === 'sm' || this.media() === 'md');
    }


    async checkAwinParams() {
        if (this.route.snapshot.queryParams['awc']?.length > 0) {
            window.localStorage.setItem('awin_awc', this.route.snapshot.queryParams['awc']);
        }
    }

    async checkCookis(googleId, googleTagId, googleadsenseid, googleMapsId, facebookpixelid, matomoid, matomositeid, salesmanagoid, awinid, partnerid, mobile) {
        this.googleServie.updateGoogleAnalyticsdef();
        if (this.document.location.pathname.startsWith('/reisen')) {
            partnerid = 'main';
        }
        let isConsented = this.getCookie(partnerid + '_COOKIE_CONSENT') === '1';

        const isConsentedAnalytics_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS_WID') === '1';
        const isConsentedGoogleTagMg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER_WID') === '1';
        const isConsentedADSense_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEADSENSE_WID') === '1';
        const isConsentedFacebookPixelMg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL_WID') === '1';
        const isConsentedGoogleMapsg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS_WID') === '1';
        const isConsentedYoutubeg_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE_WID') === '1';

        // const isConsentedMatomog_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_MATOMO_WID') === '1';
        const isConsentedSalesmanagog_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_SALESMANAGO_WID') === '1';
        const isConsentedAwing_WID = this.getCookie(partnerid + '_COOKIE_CONSENT_AWIN_WID') === '1';



        const isConsentedAnalytics = this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS') === '1';
        const isConsentedGoogleTagMg = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER') === '1';
        const isConsentedADSense = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEADSENSE') === '1';
        const isConsentedFacebookPixelMg = this.getCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL') === '1';
        const isConsentedGoogleMapsg = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS') === '1';
        const isConsentedYOUTUBEg = this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE') === '1';

        // const isConsentedMATOMOg = '1'// this.getCookie(partnerid + '_COOKIE_CONSENT_MATOMO') === '1';
        const isConsentedSALESMANAGOEg = this.getCookie(partnerid + '_COOKIE_CONSENT_SALESMANAGO') === '1';
        const isConsentedAWINg = this.getCookie(partnerid + '_COOKIE_CONSENT_AWIN') === '1';

        if (
            (!isConsentedAnalytics && !isConsentedAnalytics_WID && googleId?.length > 0) ||
            (!isConsentedGoogleTagMg && !isConsentedGoogleTagMg_WID && googleTagId?.length > 0) ||
            (!isConsentedADSense && !isConsentedADSense_WID && googleadsenseid?.length > 0) ||

            // (!isConsentedMATOMOg && !isConsentedMatomog_WID && matomoid?.length > 0) ||
            (!isConsentedSALESMANAGOEg && !isConsentedSalesmanagog_WID && salesmanagoid?.length > 0) ||
            (!isConsentedAWINg && !isConsentedAwing_WID && awinid?.length > 0) ||

            (!isConsentedGoogleMapsg && !isConsentedGoogleMapsg_WID && googleMapsId?.length > 0) ||
            (!isConsentedYOUTUBEg && !isConsentedYoutubeg_WID) ||
            (!isConsentedFacebookPixelMg && !isConsentedFacebookPixelMg_WID && facebookpixelid?.length > 0)
        ) {
            isConsented = false;
        }
        if (!isConsented) {
            this.openCookies(googleId, googleTagId, googleadsenseid, googleMapsId, facebookpixelid, matomoid, matomositeid, salesmanagoid, awinid, true, partnerid, this.smallMode());
        } else {
            if (isConsentedAnalytics && googleId?.length > 0) {
                this.setGAnalytics(googleId);
            }

            if (isConsentedGoogleTagMg && googleTagId?.length > 0) {
                this.setGoogleTagManager(googleTagId);
            }

            if (isConsentedADSense && googleadsenseid?.length > 0) {
                this.setGoogleADSense(googleTagId);
            }

            if (isConsentedGoogleMapsg && googleMapsId?.length > 0) {
                this.setGoogleMap(googleMapsId);
            }

            if (isConsentedFacebookPixelMg && facebookpixelid?.length > 0) {
                this.setFacebookPixelManager(facebookpixelid);
            }
             // new consents
            // if (isConsentedMATOMOg && matomoid?.length > 0) {
                // this.initTrackingMatomo(matomoid, matomositeid);
            // }
            this.initTrackingMatomo(matomoid, matomositeid);

            if (isConsentedSALESMANAGOEg && salesmanagoid?.length > 0) {
                this.initTrackingsalesmanagoid(salesmanagoid);
            }

            if (isConsentedAWINg && awinid?.length > 0) {
                this.initTrackingawinid(awinid);
            }
        }
    }

    async initTrackingawinid(trackingID) {
        const awMastertag = document.createElement('script');
        awMastertag.setAttribute('defer', 'defer');
        awMastertag.src = `https://www.dwin1.com/${trackingID}.js`;
        awMastertag.type = 'text/javascript';

        document.getElementsByTagName('body')[0].appendChild(awMastertag);
        setTimeout(() => {
            this.awinsubject.next(true);
        }, 100);
    }

    awinsubject$(): Observable<boolean> {
        return this.awinsubject.asObservable();
    }

    async initTrackingMatomo(trackingID, matomositeid) {
        if (trackingID?.length > 0) {
            const gaScript = document.createElement('script');
            gaScript.innerText = ` var _paq = window._paq = window._paq || [];/* tracker methods like "setCustomDimension" should be called before "trackPageView" */_paq.push(['trackPageView']);_paq.push(['enableLinkTracking']);(function() {var u='${trackingID}';_paq.push(['setTrackerUrl', u+'matomo.php']);_paq.push(['setSiteId', '${matomositeid}']);var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);})();`;
    
            document.documentElement.firstChild.appendChild(gaScript);
            setTimeout(() => {
                this.matomosubject.next(true);
            }, 100);
        }
    }

    matomosubject$(): Observable<boolean> {
        return this.matomosubject.asObservable();
    }

    async initTrackingsalesmanagoid(trackingID) {
        const gaScript = document.createElement('script');
        gaScript.innerText = `var _smid = '${trackingID}';var _smapp = 1;var _smcustom = true;(function(w, r, a, sm, s ) {w['SalesmanagoObject'] = r;w[r] = w[r] || function () {( w[r].q = w[r].q || [] ).push(arguments)};sm = document.createElement('script');sm.type = 'text/javascript'; sm.async = true; sm.src = a;s = document.getElementsByTagName('script')[0];s.parentNode.insertBefore(sm, s);})(window, 'sm', ('https:' == document.location.protocol ? 'https://' : 'http://')+ 'www.salesmanago.com/static/sm.js');`;
        document.body.appendChild(gaScript);

        setTimeout(() => {
            this.salesmanagosubject.next(true);
        }, 100);
    }

    salesmanagosubject$(): Observable<boolean> {
        return this.salesmanagosubject.asObservable();
    }

    postmessageconsent(partnerid):any {
        return {
            consents: [
                {
                    name: 'analytics',
                    consent: this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS') === '1'
                },
                {
                    name: 'essential',
                    consent: true
                },
                {
                    name: 'youtube',
                    consent: this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE') === '1'
                },
                {
                    name: 'googl-tag-manager',
                    consent: this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER') === '1'
                },
            ]
        }
    }

    @HostListener('window:beforeunload', ['$event'])
    ngOnDestroy(): void {
        this.unsubscribe$.next(true);
        this.unsubscribe$.complete();
    }

    openCookies(googleId, googleTagId, googleadsenseid, googleMapsId, facebookpixelid, matomoid, matomositeid, salesmanagoid, awinid, auto, partnerid, mobile) {
        if (this.isBrowser) {
            const isConsentedAnalytics = this.getCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS') === '1';
            const isConsentedGoogleTagMg = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER') === '1';
            const isConsentedGoogleAdsense = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEADSENSE') === '1';
            const isConsentedGoogleMaps = this.getCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS') === '1';
            const isConsentedYouTube = this.getCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE') === '1';
            const isConsentedFacebookPixelMg = this.getCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL') === '1';
    
            const isConsentedMATOMOg = ''; // this.getCookie(partnerid + '_COOKIE_CONSENT_MATOMO') === '1';
            const isConsentedSALESMANAGOEg = this.getCookie(partnerid + '_COOKIE_CONSENT_SALESMANAGO') === '1';
            const isConsentedAWINg = this.getCookie(partnerid + '_COOKIE_CONSENT_AWIN') === '1';
    
            const config = new MatDialogConfig();
            config.panelClass = 'cookiePanel';
            config.maxWidth = '600px';
            config.maxHeight = '100%';
            if (this.smallMode()) {
                config.height = '100%';
            }
            config.autoFocus = false;
            if (auto) {
                config.hasBackdrop = false;
                config.position = { bottom: '.5rem' };
            }
            config.data = {
                components: [
                    {
                        name: 'Google Analytics',
                        checked: isConsentedAnalytics,
                        id: googleId,
                        description: 'Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus gewonnenen Daten werden genutzt, um unsere Website sowie Werbemaßnahmen zu optimieren.',
                        privacy: 'https://policies.google.com/privacy'
                    },
                    {
                        name: 'Google Tag Manager',
                        checked: isConsentedGoogleTagMg,
                        id: googleTagId,
                        description: 'Google Tag-Management-System. Damit kann der Benutzer Messcodes und zugehörige Codefragmente aktualisieren, die auf der Website des Benutzers oder in der mobilen App als Tags bezeichnet werden.',
                        privacy: 'https://policies.google.com/privacy'
                    },
                    {
                        name: 'Google ADSense',
                        checked: isConsentedGoogleAdsense,
                        id: googleadsenseid,
                        description: 'Google AdSense ist ein Online-Dienst des US-amerikanischen Unternehmens Google LLC, der Werbung auf Websites außerhalb der hauseigenen Angebote darstellt. Kern des Werbedienstes ist ein Algorithmus, der bei der Auswahl der Werbung die Inhalte der Zielwebseite berücksichtigt.',
                        privacy: 'https://policies.google.com/privacy'
                    },
                    {
                        name: 'Google Maps',
                        checked: isConsentedGoogleMaps,
                        id: googleMapsId,
                        description: 'Google Maps ist ein Web-Mapping-Dienst. Die gesammelten Daten werden für das anzeigen von Karten verwendet.',
                        privacy: 'https://policies.google.com/privacy'
                    },
                    {
                        name: 'YouTube',
                        checked: isConsentedYouTube,
                        id: 'YouTube',
                        description: 'Wird verwendet, um integrierte Inhalte der Videoplattform YouTube auf unserer Website freizuschalten. Die durch die Cookies erzeugten Informationen über Benutzung des Onlineangebotes durch die Nutzer werden.',
                        privacy: 'https://policies.google.com/privacy'
                    },
                    {
                        name: 'Facebook Pixel',
                        checked: isConsentedFacebookPixelMg,
                        id: facebookpixelid,
                        description: 'Das ist ein Werbung-messen-System.'
                    },
                    {
                        name: 'Matomo',
                        checked: isConsentedMATOMOg,
                        withoutcheckbox: true,
                        id: matomoid,
                        description: 'Diese Webseite verwendet Matomo, um anonyme Nutzungsstatistiken zu erfassen und unsere Webseite zu verbessern. Wir benötigen Ihre Einwilligung zur Verwendung von Matomo. Sie können jederzeit Ihre Einwilligung widerrufen. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
                        privacy: 'https://de.matomo.org/privacy-policy/'
                    },
                    {
                        name: 'Salesmanago',
                        checked: isConsentedSALESMANAGOEg,
                        id: salesmanagoid,
                        description: 'Diese Webseite verwendet Sales Manago, um personalisierte Inhalte und Angebote zu erstellen und das Nutzerverhalten zu analysieren. Wir benötigen Ihre Einwilligung zur Verwendung von Sales Manago. Sie können jederzeit Ihre Einwilligung widerrufen. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
                        privacy: 'https://www.salesmanago.com/info/information-obligation.htm'
                    },
                    {
                        name: 'AWIN',
                        checked: isConsentedAWINg,
                        id: awinid,
                        description: 'Diese Webseite verwendet AWIN, um Affiliate-Marketing zu betreiben und die Leistung unserer Affiliate-Partner zu messen. Wir benötigen Ihre Einwilligung zur Verwendung von AWIN. Sie können jederzeit Ihre Einwilligung widerrufen. Weitere Informationen finden Sie in unserer Datenschutzerklärung.',
                        privacy: 'https://www.awin.com/de/datenschutzerklarung'
                    }
                ]
            };
    
            const dialogRef = this.dialog.open(CookieComponent, config);
            dialogRef.afterClosed().pipe(
                takeUntil(this.unsubscribe$)
            ).subscribe((result: any[]) => {
                if (result) {
                    this.resetCookies();
                    for (let i = 0; i < result?.length; i++) {
                        if (result[i].name === 'Google Analytics') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.setGAnalytics(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GANALYTICS_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'Google Tag Manager') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.setGoogleTagManager(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLETAGMANAGER_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'Google ADSense') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.setGoogleADSense(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLEADSENSE', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLEADSENSE_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'Google Maps') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.setGoogleMap(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_GOOGLEMAPS_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'YouTube') {
                            const val = result[i].checked ? '1' : '0';
                            this.setCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_YOUTUBE_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'Facebook Pixel') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.setFacebookPixelManager(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_FACEBOOKPIXEL_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
    
                        if (result[i].name === 'Matomo') {
                            const val = result[i].checked ? '1' : '0';
                            // if (val === '1') {
                            //     this.initTrackingMatomo(result[i].id, matomositeid);
                            // }
                            this.initTrackingMatomo(result[i].id, matomositeid);
                            // this.setCookie(partnerid + '_COOKIE_CONSENT_MATOMO', val, 730, partnerid);
                            // this.setCookie(partnerid + '_COOKIE_CONSENT_MATOMO_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'Salesmanago') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.initTrackingsalesmanagoid(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_SALESMANAGO', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_SALESMANAGO_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                        if (result[i].name === 'AWIN') {
                            const val = result[i].checked ? '1' : '0';
                            if (val === '1') {
                                this.initTrackingawinid(result[i].id);
                            }
                            this.setCookie(partnerid + '_COOKIE_CONSENT_AWIN', val, 730, partnerid);
                            this.setCookie(partnerid + '_COOKIE_CONSENT_AWIN_WID', (result[i].id?.length > 0 ? '1' : '0'), 730, partnerid);
                        }
                    }
    
                    this.setCookie(partnerid + '_COOKIE_CONSENT', '1', 730, partnerid);
                    // this.setCookie(partnerid + '_COOKIE_CONSENT_RES', moment().format('YYYY-MM-DD'), 730, partnerid);
                    window.location.reload();
                }
            });
        }
    }

    setGAnalytics(id) {
        if (id?.length > 0) {
            this.googleServie.loadGoogleAnalytics();
        }
    }

    setGoogleTagManager(id) {
        if (id?.length > 0) {
            this.googleServie.updateGoogleTagsdef();
        }
    }

    setGoogleADSense(id) {
        if (id?.length > 0) {
            this.googleServie.loadGoogleAdsense(id);
        }
    }

    setGoogleMap(id) {
        if (id?.length > 0) {
            this.googleServie.loadGoogleMap(id);
        }
    }

    setFacebookPixelManager(id) {
        if (id?.length > 0) {
            this.facebookService.loadFacebookPixelManager(id);
        }
    }

    resetCookies(): void {
        const allCookies = this.document.cookie.split(';');

        // The "expire" attribute of every cookie is
        // Set to "Thu, 01 Jan 1970 00:00:00 GMT"
        for (let i = 0; i < allCookies.length; i++) {
            this.document.cookie = allCookies[i] + '=;expires=' +
                new Date(0).toUTCString();
        }
    }

    getCookie(name: string) {
        const ca: Array<string> = this.document.cookie.split(';');
        const caLen: number = ca.length;
        const cookieName = `${name}=`;
        let c: string;

        for (let i: number = 0; i < caLen; i += 1) {
            c = ca[i].replace(/^\s+/g, '');
            if (c.indexOf(cookieName) === 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    setCookie(name: string, value: string, expireDays: number, path: string = '') {
        const d: Date = new Date();
        d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
        const expires: string = `expires=${d.toUTCString()}`;
        const cpath: string = path ? '; path=/' : '';
        // const cpath1: string = `; path=/${path}`;
        // document.cookie = `${name}=${value}; expires=Thu, 01 Jan 1970 00:00:00 GMT${cpath1}`;
        this.document.cookie = `${name}=${value}; ${expires}${cpath}`;
    }
}
